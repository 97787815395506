


















import { Component, Vue, Prop } from "vue-property-decorator";
import { Document } from "@/models/document.model";

@Component({
  components: { },
})
export default class JnFacebookSharing extends Vue {

@Prop({ required: false })
  doc?: Document;

}
