




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/models/document.model';
import JnFacebookSharing from '@/views/sharing/FacebookSharing.vue';
import JnLinkedinSharing from '@/views/sharing/LinkedinSharing.vue';
import EventBus from '@/services/event.bus';
import { CategoriesFormulari } from "@/models/enums.model";

@Component({
  components: { JnFacebookSharing, JnLinkedinSharing },
})
export default class JnDoc extends Vue {
  @Prop({ required: true })
  doc!: Document;

  view() {
    this.$router.push('/docs/juranews/' + this.doc.id);
  }

  isFormulario() {
    return (
      CategoriesFormulari.includes(String(this.doc.idCategory))
    );
  }

  mounted() {
    EventBus.$on('search-in-page', (keywords: string) => {
      this.doc.abstract = this.doc!.abstract!.replaceAll('<mark>', '');
      this.doc.abstract = this.doc!.abstract!.replaceAll('</mark>', '');
      const words = keywords.trim().split(' ');
      for (const w of words) {
        if (w.trim() != '') {
          const strReplace = w;
          const esc = strReplace.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
          const reg = new RegExp(esc, 'ig');
          this.doc!.abstract = this.doc?.abstract!.replace(
            reg,
            '<mark>' + w + '</mark>'
          );
        }
      }
    });
  }
}
