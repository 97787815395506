








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { EsService } from '@/services/es.service';
import JnDoc from '@/views/common/DocCard.vue';
import { CarouselGroup } from '@/models/carousel-group.model';
import { Categories } from '@/models/enums.model';

@Component({
  components: { JnDoc },
})
export default class JnMainNewsGroup extends Vue {
  @Prop({ required: true, default: '' })
  props!: CarouselGroup;

  @Prop({ required: true })
  searchData!: SearchData;

  news: Document[] = [];

  async mounted() {
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES!,
      from: this.props.from,
      size: 2,
      featured: 1,
      idCategory: [Categories.ULTIME_NEWS],
    });
    this.news = await EsService.getDocs(this.searchData);
  }
}
