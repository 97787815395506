






































































































































import JnAreeTematicheSearch from "@/views/search/AreeTematicheSearch.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnSearchResults from "@/views/search/SearchResults.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { Testata } from "@/models/testata.model";
import { SearchData } from "@/models/search.model";
import MetaInfo from "vue-meta";
import {
  Categories,
  RoutesToCategories,
  RoutesToNames,
} from "@/models/enums.model";
import { EsService } from "@/services/es.service";
import JnMainNewsGroup from "@/views/common/MainNewsGroup.vue";
import EventBus from "@/services/event.bus";
import { Pagination } from "@/models/pagination.model";
import { Document } from "@/models/document.model";
import JnDoc from "@/views/common/DocCard.vue";

@Component({
  components: {
    JnAreeTematicheSearch,
    JnCardsStrip,
    JnSearchResults,
    JnMainNewsGroup,
    JnDoc
  },
  metaInfo(this: JnAreaTematicaInterna): MetaInfo {
    return {
      title: "JuraNews - Area Tematica - " + RoutesToNames[this.name],
    };
  },
})
export default class JnAreaTematicaInterna extends Vue {
  @Prop({ required: true })
  name!: string;

  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    idAreaTematica: [RoutesToCategories[this.name]],
    searchScope: "area-tematica-" + this.name,
  });

  commentoPagination?: Pagination;
  commentoAllDocs: Document[] = [];
  commentoShownDocs: Document[] = [];

  schedaAutoralePagination?: Pagination;
  schedaAutoraleAllDocs: Document[] = [];
  schedaAutoraleShownDocs: Document[] = [];

  commentoSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Categories.COMMENTI, Categories.SINTESI_GIURISPRUDENZIALI],
    idAreaTematica: [RoutesToCategories[this.name]],
    withText: true,
  });
  schedaAutoraleSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Categories.SCHEDA_AUTORALE],
    idAreaTematica: [RoutesToCategories[this.name]],
    withText: true,
  });

  async beforeMount() {
    this.testata = JnService.getTestata(RoutesToCategories[this.name]);

    this.commentoAllDocs = await EsService.getDocs(this.commentoSearchData);
    this.commentoPagination = new Pagination({
      page: 1,
      total: this.commentoAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showCommento(0, this.commentoPagination.perPage);

    this.schedaAutoraleAllDocs = await EsService.getDocs(
      this.schedaAutoraleSearchData
    );
    this.schedaAutoralePagination = new Pagination({
      page: 1,
      total: this.schedaAutoraleAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showSchedaAutorale(0, this.schedaAutoralePagination.perPage);
  }

  get searchScope() {
    return "area-tematica-" + this.name;
  }

  updated() {
    EventBus.$emit("search-results", []);
  }

  // Commento
  nextCommento() {
    if (this.commentoPagination) {
      const from =
        (this.commentoPagination.page - 1) * this.commentoPagination.perPage;
      const size = this.commentoPagination.perPage;
      this.showCommento(from, size);
    }
  }
  showCommento(from: number, size: number) {
    this.commentoShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.commentoAllDocs[i]) {
        this.commentoShownDocs.push(this.commentoAllDocs[i]);
      }
    }
  }

  // Scheda Autorale
  nextSchedaAutorale() {
    if (this.schedaAutoralePagination) {
      const from =
        (this.schedaAutoralePagination.page - 1) *
        this.schedaAutoralePagination.perPage;
      const size = this.schedaAutoralePagination.perPage;
      this.showSchedaAutorale(from, size);
    }
  }
  showSchedaAutorale(from: number, size: number) {
    this.schedaAutoraleShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.schedaAutoraleAllDocs[i]) {
        this.schedaAutoraleShownDocs.push(this.schedaAutoraleAllDocs[i]);
      }
    }
  }
}
